
import { Vue, Options } from 'vue-class-component'
import RemoteControl from './src/components/remoteControl/index.vue'
import Mappingkey from './src/components/mappingKeys/index.vue'
import { testApi } from '@/Api/testApi'
@Options({
  components: {
    RemoteControl,
    Mappingkey,
  },
})
export default class KeyControlPage extends Vue {
  rpcName: string = 'tuner.channel.get'
  mapKeyShow: boolean = true
  socket: any

  public mounted(): void {
    console.log('mounted')
    // this.initSocket()
  }
  /**
   * openMap
   */
  public openMapKey() {
    this.mapKeyShow = true
  }

  /**
   * testSoket
   */
  public testSoket() {
    // ccApi
    testApi().then(res=>{
      console.log('testPI=>>',res)
    })
  }

  /**
   * getKeyMapList 获取keyMap列表
   */
  public getKeyMapList() {}
}
