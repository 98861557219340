import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9df5af30"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "key_control_page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RemoteControl = _resolveComponent("RemoteControl")!
  const _component_Mappingkey = _resolveComponent("Mappingkey")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RemoteControl, {
      class: "remote_control",
      onOnMapkey: _ctx.openMapKey
    }, null, 8, ["onOnMapkey"]),
    _createVNode(_component_a_modal, {
      width: "6.04rem",
      title: "Mapping Keys",
      visible: _ctx.mapKeyShow,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mapKeyShow) = $event)),
      closable: false,
      footer: null
    }, {
      default: _withCtx(() => [
        (_ctx.mapKeyShow)
          ? (_openBlock(), _createBlock(_component_Mappingkey, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"]),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rpcName) = $event))
    }, null, 512), [
      [_vModelText, _ctx.rpcName]
    ]),
    _createVNode(_component_a_button, { onClick: _ctx.testSoket }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.rpcName) + "测试", 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}