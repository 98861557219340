

import { SetupContext } from 'vue'
import iconMixin from '../../mixins/iconMixin'
const numsBtn = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 'BACK']
export default {
  name: 'RemoteControl',
  mixins:[iconMixin],
  data() {
    return {
      numsBtn,
    }
  },
  emits:['onMapkey'],
  setup(props: any, ctx: SetupContext) {
    const { emit } = ctx
    const clickMapKey = () => {
      emit('onMapkey')
    }
    return {
      clickMapKey
    }
  },
}
